import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, RouterStateSnapshot } from '@angular/router';
import { RouterService } from '@app/services/router/router.service';
import { StateService } from '@app/services/state/state.service';
import { UtilsService } from '@app/services/utils/utils.service';

@Injectable({
  providedIn: 'root',
})
class AuthGuardService {
  constructor(
    private readonly stateSrv: StateService,
    private readonly routerSrv: RouterService,
    private readonly utilsSrv: UtilsService,
  ) {}

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!!this.stateSrv.state.user()) {
      return true;
    }

    this.utilsSrv.isPlatformBrowser && this.routerSrv.navigate([]);

    return false;
  }
}

export const authGuard: CanActivateChildFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean =>
  inject(AuthGuardService).canActivateChild(next, state);
