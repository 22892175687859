import { Routes } from '@angular/router';
import { DEFAULT_LANG } from './constants/global.constants';
import { Navigation, Paths, getNavigationRoutePath } from './constants/navigation.constants';
import { langGuard } from './guards/lang/lang.guard';
import { pageConfigGuard } from './guards/page-config/page-config.guard';
import { seoGuard } from './guards/seo/seo.guard';
import { IStatePageConfig } from './interfaces/state.interface';
import { authGuard } from './guards/auth/auth.guard';
import { redirectGuard } from './guards/redirect/redirect.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: `/${DEFAULT_LANG}`,
    pathMatch: 'full',
  },
  {
    path: ':lang',
    canActivateChild: [langGuard, seoGuard],
    children: [
      {
        path: getNavigationRoutePath(Paths[Navigation.Home]),
        loadComponent: () => import('./pages/home/home.component').then((c) => c.HomePageComponent),
        canActivate: [pageConfigGuard],
        data: {
          pageConfig: {
            headerBorderBottom: false,
          } as IStatePageConfig,
        },
      },
      {
        path: getNavigationRoutePath(Paths[Navigation.Profiles]),
        loadComponent: () => import('./pages/profiles/profiles.component').then((c) => c.ProfilesPageComponent),
        canActivate: [pageConfigGuard],
      },
      {
        path: getNavigationRoutePath(Paths[Navigation.Techie]),
        loadComponent: () => import('./pages/techie/techie.component').then((c) => c.TechiePageComponent),
        canActivate: [pageConfigGuard],
        data: {
          pageConfig: {
            footer: false,
          } as IStatePageConfig,
        },
      },
      {
        path: getNavigationRoutePath(Paths[Navigation.Login]),
        loadComponent: () => import('./pages/login/login.component').then((c) => c.LoginPageComponent),
        canActivate: [pageConfigGuard, redirectGuard],
        data: {
          pageConfig: {
            header: false,
            footer: false,
          } as IStatePageConfig,
        },
      },
      {
        path: getNavigationRoutePath(Paths[Navigation.Register]),
        loadComponent: () => import('./pages/register/register.component').then((c) => c.RegisterPageComponent),
        canActivate: [pageConfigGuard, redirectGuard],
        data: {
          pageConfig: {
            header: false,
            footer: false,
          } as IStatePageConfig,
        },
      },
      {
        path: getNavigationRoutePath(Paths[Navigation.ForgotPassword]),
        loadComponent: () =>
          import('./pages/forgot-password/forgot-password.component').then((c) => c.ForgotPasswordPageComponent),
        canActivate: [pageConfigGuard, redirectGuard],
        data: {
          pageConfig: {
            header: false,
            footer: false,
          } as IStatePageConfig,
        },
      },
      {
        path: getNavigationRoutePath(Paths[Navigation.ResetPassword]),
        loadComponent: () =>
          import('./pages/reset-password/reset-password.component').then((c) => c.ResetPasswordPageComponent),
        canActivate: [pageConfigGuard, redirectGuard],
        data: {
          pageConfig: {
            header: false,
            footer: false,
          } as IStatePageConfig,
        },
      },
      {
        path: getNavigationRoutePath(Paths[Navigation.Profile]),
        loadComponent: () => import('./pages/profile/profile.component').then((c) => c.ProfilePageComponent),
        canActivate: [pageConfigGuard, authGuard],
        data: {
          pageConfig: {
            footer: false,
          } as IStatePageConfig,
        },
      },
      {
        path: getNavigationRoutePath(Paths[Navigation.TechieForm]),
        loadComponent: () => import('./pages/techie-form/techie-form.component').then((c) => c.TechieFormPageComponent),
        canActivate: [pageConfigGuard, authGuard],
        data: {
          pageConfig: {
            headerActions: false,
            footer: false,
          } as IStatePageConfig,
        },
      },
      {
        path: getNavigationRoutePath(Paths[Navigation.Settings]),
        loadComponent: () => import('./pages/settings/settings.component').then((c) => c.SettingsPageComponent),
        canActivate: [pageConfigGuard],
        data: {
          pageConfig: {
            footer: false,
          } as IStatePageConfig,
        },
      },
      {
        path: getNavigationRoutePath(Paths[Navigation.Terms]),
        loadComponent: () => import('./pages/terms/terms.component').then((c) => c.TermsPageComponent),
        canActivate: [pageConfigGuard],
      },
      {
        path: getNavigationRoutePath(Paths[Navigation.Privacy]),
        loadComponent: () => import('./pages/privacy/privacy.component').then((c) => c.PrivacyPageComponent),
        canActivate: [pageConfigGuard],
      },
    ],
  },
  {
    path: '**',
    loadComponent: () => import('./pages/not-found/not-found.component').then((c) => c.NotFoundPageComponent),
    canActivate: [langGuard, pageConfigGuard],
  },
];
