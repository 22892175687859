import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { IStatePageConfig } from '@app/interfaces/state.interface';
import { StateService } from '@app/services/state/state.service';

@Injectable({
  providedIn: 'root',
})
class PageConfigService {
  constructor(private readonly stateSrv: StateService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const pageConfigRouteData: IStatePageConfig = route.data['pageConfig'];

    this.stateSrv.update<IStatePageConfig>('pageConfig', {
      header: pageConfigRouteData?.header ?? true,
      headerBorderBottom: pageConfigRouteData?.headerBorderBottom ?? true,
      headerActions: pageConfigRouteData?.headerActions ?? true,
      footer: pageConfigRouteData?.footer ?? true,
    });

    return true;
  }
}

export const pageConfigGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean =>
  inject(PageConfigService).canActivate(next, state);
