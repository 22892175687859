import { BreakpointObserver } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { LAYOUT_BREAKPOINT, LAYOUT_BREAKPOINT_MAP } from '@app/constants/global.constants';
import { StateService } from '../state/state.service';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private readonly layoutToBreakpoint = new Map<LAYOUT_BREAKPOINT, string>([
    [LAYOUT_BREAKPOINT.MOBILE, `(max-width: ${LAYOUT_BREAKPOINT_MAP.get(LAYOUT_BREAKPOINT.MOBILE)}px)`],
    [
      LAYOUT_BREAKPOINT.TABLET,
      `(min-width: ${LAYOUT_BREAKPOINT_MAP.get(LAYOUT_BREAKPOINT.MOBILE) + 1}px) and (max-width: ${LAYOUT_BREAKPOINT_MAP.get(LAYOUT_BREAKPOINT.TABLET)}px)`,
    ],
    [
      LAYOUT_BREAKPOINT.DESKTOP,
      `(min-width: ${LAYOUT_BREAKPOINT_MAP.get(LAYOUT_BREAKPOINT.TABLET) + 1}px) and (max-width: ${LAYOUT_BREAKPOINT_MAP.get(LAYOUT_BREAKPOINT.DESKTOP)}px)`,
    ],
    [
      LAYOUT_BREAKPOINT.DESKTOP_LG,
      `(min-width: ${LAYOUT_BREAKPOINT_MAP.get(LAYOUT_BREAKPOINT.DESKTOP) + 1}px) and (max-width: ${LAYOUT_BREAKPOINT_MAP.get(LAYOUT_BREAKPOINT.DESKTOP_LG)}px)`,
    ],
    [LAYOUT_BREAKPOINT.DESKTOP_XL, `(min-width: ${LAYOUT_BREAKPOINT_MAP.get(LAYOUT_BREAKPOINT.DESKTOP_LG) + 1}px)`],
  ]);

  private readonly breakpointToLayout = new Map<string, LAYOUT_BREAKPOINT>([
    [`(max-width: ${LAYOUT_BREAKPOINT_MAP.get(LAYOUT_BREAKPOINT.MOBILE)}px)`, LAYOUT_BREAKPOINT.MOBILE],
    [
      `(min-width: ${LAYOUT_BREAKPOINT_MAP.get(LAYOUT_BREAKPOINT.MOBILE) + 1}px) and (max-width: ${LAYOUT_BREAKPOINT_MAP.get(LAYOUT_BREAKPOINT.TABLET)}px)`,
      LAYOUT_BREAKPOINT.TABLET,
    ],
    [
      `(min-width: ${LAYOUT_BREAKPOINT_MAP.get(LAYOUT_BREAKPOINT.TABLET) + 1}px) and (max-width: ${LAYOUT_BREAKPOINT_MAP.get(LAYOUT_BREAKPOINT.DESKTOP)}px)`,
      LAYOUT_BREAKPOINT.DESKTOP,
    ],
    [
      `(min-width: ${LAYOUT_BREAKPOINT_MAP.get(LAYOUT_BREAKPOINT.DESKTOP) + 1}px) and (max-width: ${LAYOUT_BREAKPOINT_MAP.get(LAYOUT_BREAKPOINT.DESKTOP_LG)}px)`,
      LAYOUT_BREAKPOINT.DESKTOP_LG,
    ],
    [`(min-width: ${LAYOUT_BREAKPOINT_MAP.get(LAYOUT_BREAKPOINT.DESKTOP_LG) + 1}px)`, LAYOUT_BREAKPOINT.DESKTOP_XL],
  ]);

  constructor(
    private stateSrv: StateService,
    private breakpointObserver: BreakpointObserver,
  ) {}

  init() {
    this.breakpointObserver
      .observe([
        this.layoutToBreakpoint.get(LAYOUT_BREAKPOINT.MOBILE),
        this.layoutToBreakpoint.get(LAYOUT_BREAKPOINT.TABLET),
        this.layoutToBreakpoint.get(LAYOUT_BREAKPOINT.DESKTOP),
        this.layoutToBreakpoint.get(LAYOUT_BREAKPOINT.DESKTOP_LG),
        this.layoutToBreakpoint.get(LAYOUT_BREAKPOINT.DESKTOP_XL),
      ])
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.stateSrv.update('layout', this.breakpointToLayout.get(query));
          }
        }
      });
  }
}
