import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, RouterStateSnapshot } from '@angular/router';
import { Navigation } from '@app/constants/navigation.constants';
import { ROUTE_DATA_MAP, RouteData } from '@app/constants/seo.constants';
import { ISEOContent } from '@app/interfaces/seo.interface';
import { LanguageService } from '@app/services/language/language.service';
import { SeoService } from '@app/services/seo/seo.service';
import { UtilsService } from '@app/services/utils/utils.service';
import { Observable, first, map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
class SeoGuardService {
  private readonly http: HttpClient = inject(HttpClient);
  private readonly utilsSrv: UtilsService = inject(UtilsService);
  private readonly seoSrv: SeoService = inject(SeoService);
  private readonly langSrv: LanguageService = inject(LanguageService);

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const data = this.getRouteData(route.data['key']);
    const titleKey = data[RouteData.Title];
    const descriptionKey = data[RouteData.Description];
    const path = state.url;

    let seoParamsObs: Observable<ISEOContent>;

    if (this.utilsSrv.isPlatformBrowser) {
      seoParamsObs = of({
        ...(titleKey && { title: this.langSrv.translate(titleKey) }),
        description: this.langSrv.translate(descriptionKey) || this.langSrv.translate('default_meta_description'),
        path,
      });
    } else {
      seoParamsObs = this.http.get(`i18n/${this.langSrv.getCurrentLang()}.json`).pipe(
        first((res) => !!res),
        map((res) => ({
          ...(titleKey && { title: this.getValueFromKey(res, titleKey) }),
          description:
            this.getValueFromKey(res, descriptionKey) || this.getValueFromKey(res, 'default_meta_description'),
          path,
        })),
      );
    }

    return seoParamsObs.pipe(first((res) => !!res)).pipe(
      map((seoParams) => {
        this.seoSrv.set(seoParams, this.langSrv.getCurrentLang());

        return true;
      }),
    );
  }

  private getRouteData(key: Navigation): { [RouteData.Title]?: string; [RouteData.Description]: string } {
    return ROUTE_DATA_MAP.get(key) || { [RouteData.Description]: 'default_meta_description' };
  }

  private getValueFromKey(obj: { [key: string]: any }, key: string) {
    if (key.indexOf('.') === -1) {
      return obj[key];
    }

    const keys = key.split('.');

    let result = obj;

    for (const k of keys) {
      if (result[k] !== undefined) {
        result = result[k];
      } else {
        return '';
      }
    }

    return result;
  }
}

export const seoGuard: CanActivateChildFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): boolean | Observable<boolean> => inject(SeoGuardService).canActivateChild(next, state);
