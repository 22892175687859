import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, Params, Router, RouterStateSnapshot } from '@angular/router';
import { DEFAULT_LANG, LANGS_LIST } from '@app/constants/global.constants';
import { LanguageService } from '@app/services/language/language.service';
import { StateService } from '@app/services/state/state.service';
import { Lang } from '@app/types/app.types';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
class LangGuardService {
  private readonly router: Router = inject(Router);
  private readonly stateSrv: StateService = inject(StateService);
  private readonly langSrv: LanguageService = inject(LanguageService);

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const urlSegments = state.url.split('/');
    const lang = urlSegments[1] as Lang;
    const supportedLanguages = LANGS_LIST;

    if (!supportedLanguages.includes(lang)) {
      this.navigateToLang(state.url, route.queryParams);

      return false;
    } else if (!!this.stateSrv.state.lang() && lang !== this.stateSrv.state.lang()) {
      this.langSrv.changeLanguage(lang);
    }

    return true;
  }

  private navigateToLang(url: string, queryParams: Params) {
    const lang = this.stateSrv.state.lang() || DEFAULT_LANG;
    const baseUrl = url.split('?')[0];
    const path = `${lang}${baseUrl}`;

    this.router.navigate([path], { queryParams });
  }
}

export const langGuard: CanActivateChildFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): boolean | Observable<boolean> => inject(LangGuardService).canActivateChild(next, state);
