import { Injectable } from '@angular/core';
import { IOptions } from '@app/interfaces/options.interface';
import { HttpBaseService } from '@app/services/http/http.service';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OptionsApiService extends HttpBaseService {
  private readonly baseUrl = 'options';

  getAll(): Observable<IOptions> {
    return this.Get<{ statusCode: number; data: IOptions }>(`${this.baseUrl}/all`).pipe(map((res) => res.data));
  }
}
