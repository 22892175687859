import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { BaseComponent } from '../base/base.component';
import { BrandComponent } from '../brand/brand.component';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [TranslateModule, MatIconModule, BrandComponent],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent extends BaseComponent {
  readonly fullYear = `@${new Date().getFullYear()}`;
}
