import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@NgModule({
  imports: [CommonModule],
})
export class IconModule {
  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    const icons = [
      { name: 'linkedin', path: '/icons/brands/linkedin.svg' },
      { name: 'behance', path: '/icons/brands/behance.svg' },
      { name: 'dribbble', path: '/icons/brands/dribbble.svg' },
      { name: 'github', path: '/icons/brands/github.svg' },
      { name: 'medium', path: '/icons/brands/medium.svg' },
      { name: 'stack_overflow', path: '/icons/brands/stack-overflow.svg' },
      { name: 'youtube', path: '/icons/brands/youtube.svg' },
      { name: 'instagram', path: '/icons/brands/instagram.svg' },
      { name: 'twitter', path: '/icons/brands/twitter.svg' },
      { name: 'twitch', path: '/icons/brands/twitch.svg' },
      { name: 'whatsapp', path: '/icons/brands/whatsapp.svg' },
      { name: 'linkedin_naked_white', path: '/icons/brands/linkedin-naked-white.svg' },
    ];

    icons.forEach((icon) => {
      iconRegistry.addSvgIcon(icon.name, sanitizer.bypassSecurityTrustResourceUrl(icon.path));
    });
  }
}
