import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { BaseComponent } from './components/base/base.component';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { LayoutService } from './services/layout/layout.service';
import { CommonModule } from '@angular/common';
import { IconModule } from './icon.module';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LoaderComponent } from './components/loader/loader.component';
import { GoogleService } from './services/google/google.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    IconModule,
    MatSidenavModule,
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent extends BaseComponent implements OnInit {
  @HostBinding('class.nb-ds') addDsClass = true;

  @ViewChild('sidenavRef') set sidenavRef(sidenavRef: MatSidenav) {
    sidenavRef && this.sidenavSrv.set(sidenavRef);
  }

  constructor(
    private layoutSrv: LayoutService,
    private googleSrv: GoogleService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.layoutSrv.init();
    this.googleSrv.init();
  }
}
