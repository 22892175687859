import { Injectable, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ISEOContent } from '@app/interfaces/seo.interface';
import { Lang } from '@app/types/app.types';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  private readonly siteName = 'Neboolar';

  private readonly title: Title = inject(Title);
  private readonly meta: Meta = inject(Meta);

  set(params: ISEOContent, currentLang: Lang): void {
    const { title, description, path } = params;

    this.removeAllMetaTags();
    this.setTitle(title);
    this.setDescription(description);
    this.setUrl(path);
    this.setSiteName();
    this.setLocale(currentLang);
  }

  private setTitle(title: string): void {
    const titleContent = title ? `${title} | ${this.siteName}` : this.siteName;

    this.title.setTitle(titleContent);
    this.meta.addTag({ property: 'og:title', content: titleContent });
  }

  private setDescription(description: string): void {
    this.meta.addTag({ name: 'description', content: description });
    this.meta.addTag({ property: 'og:description', content: description });
  }

  private setUrl(path: string): void {
    this.meta.addTag({ property: 'og:url', content: environment.appUrl + path });
  }

  private setSiteName(): void {
    this.meta.addTag({ property: 'og:site_name', content: this.siteName });
  }

  private setLocale(lang: Lang): void {
    this.meta.addTag({ property: 'og:locale', content: lang });
  }

  private removeAllMetaTags(): void {
    const tags = [
      'name="title"',
      'name="description"',
      'property="og:title"',
      'property="og:description"',
      'property="og:url"',
      'property="og:site_name"',
      'property="og:locale"',
    ];

    tags.forEach((elem) => {
      this.meta.removeTag(elem);
    });
  }
}
