<footer>
  <div class="nb-section">
    <div class="container">
      <div class="row-item-lg">
        <app-brand></app-brand>
      </div>

      <div class="row-item-lg">
        <div class="row align-items-center justify-content-between">
          <div class="col-lg-auto mb-4 mb-lg-0">
            <div class="row">
              <div class="col-auto">
                <a class="cursor-pointer" (click)="navigate([Navigation.Privacy])">{{ 'privacy' | translate }}</a>
              </div>

              <div class="col-auto">
                <a class="cursor-pointer" (click)="navigate([Navigation.Terms])">{{ 'terms' | translate }}</a>
              </div>
            </div>
          </div>

          <div class="col-lg-auto">
            <div class="row">
              <div class="col-auto">
                <a href="https://www.instagram.com/neboolar" target="_blank">
                  <mat-icon class="d-flex" svgIcon="instagram"></mat-icon>
                </a>
              </div>

              <div class="col-auto">
                <a href="https://www.linkedin.com/company/neboolar" target="_blank">
                  <mat-icon class="d-flex" svgIcon="linkedin"></mat-icon>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row-item-lg">
        <small>{{ fullYear }} Neboolar. {{ 'all_rights_reserved' | translate }}</small>
      </div>

      <div class="row-item-lg">
        <small
          >Made with 💙 by
          <a class="underline-hover" href="https://www.linkedin.com/in/ignacio-cortes" target="_blank"
            >Ignacio Cortés</a
          ></small
        >
      </div>
    </div>
  </div>
</footer>
