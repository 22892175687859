@if (state.loader()) {
  <app-loader [type]="'page'"></app-loader>
}

<mat-sidenav-container [hasBackdrop]="true">
  <mat-sidenav #sidenavRef [fixedInViewport]="true" [autoFocus]="false">
    @if (sidenavSrv.status() === 'opened') {
      <ng-container *ngTemplateOutlet="sidenavSrv.tpl()"></ng-container>
    } @else {
      <app-loader [type]="'sidenav'"></app-loader>
    }
  </mat-sidenav>

  <div class="nb-sidenav-content">
    @if (state.pageConfig().header) {
      <app-header></app-header>
    }

    <router-outlet></router-outlet>

    @if (state.pageConfig().footer) {
      <app-footer></app-footer>
    }
  </div>
</mat-sidenav-container>
